import dynamic from 'next/dynamic';

import { Loader } from '@anchorage/common/dist/components';

import Signup from 'components/Signup';

import useIsPortoApp from 'utils/useIsPortoApp';

import css from './styles.module.css';

const ModalWithNoSSR = dynamic(() => import('components/StartModal'), {
  ssr: false,
  loading: () => <Loader className={css.loader} />,
});

export default function Index() {
  const isPorto = useIsPortoApp();

  return isPorto ? (
    <Signup />
  ) : (
    <ModalWithNoSSR description="" notAllowedRoute />
  );
}

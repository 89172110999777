import { useState } from 'react';

import { PORTO_SELF_REGISTRATION_PAGES } from 'components/Signup/helpers';

import css from './styles.module.scss';

import PageContent from '../PageContent/PageContent';
import PageSideBanner from '../PageSideBanner/PageSideBanner';

function PageContainer() {
  const [currentPage, setCurrentPage] = useState(
    PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE,
  );

  return (
    <div className={css.container}>
      <div className={css.sideBannerContainer}>
        <PageSideBanner page={currentPage} />
      </div>
      <PageContent page={currentPage} setPage={setCurrentPage} />
    </div>
  );
}

export default PageContainer;

import Image from 'next/image';

import { Text } from '@anchorage/common/dist/components';
import { PortoLogoIcon } from '@anchorage/common/dist/components/Icons';

import {
  PORTO_SELF_REGISTRATION_PAGES,
  PORTO_SELF_REGISTRATION_PAGE_TITLE,
  getPortoSelfRegistrationPageImage,
} from 'components/Signup/helpers';

import css from './styles.module.scss';

import { getLoginLink } from '../PageContent/PageContent';

type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
};

function PageSideBanner({ page }: Props) {
  return (
    <div className={css.container}>
      <div className={css.contentContainer}>
        <PortoLogoIcon className={css.anchorageLogo} />
        <div className={css.content}>
          <Image
            loading="eager"
            className={css.image}
            src={getPortoSelfRegistrationPageImage(
              PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE,
            )}
            alt="page image"
            hidden={
              page !== PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE
            }
          />
          <Image
            loading="eager"
            className={css.image}
            src={getPortoSelfRegistrationPageImage(
              PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS,
            )}
            alt="page image"
            hidden={page !== PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS}
          />
          <Image
            loading="eager"
            className={css.image}
            src={getPortoSelfRegistrationPageImage(
              PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM,
            )}
            alt="page image"
            hidden={page !== PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM}
          />
          <Image
            loading="eager"
            className={css.image}
            src={getPortoSelfRegistrationPageImage(
              PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE,
            )}
            alt="page image"
            hidden={
              page !== PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE
            }
          />
          <Text type="heading" size="huge" className={css.pageTitle}>
            {PORTO_SELF_REGISTRATION_PAGE_TITLE[page]}
          </Text>
        </div>
        <div className={css.footer}>
          <Text>
            {`${'Already have an account? '}`}
            <a className={css.footerLink} href={getLoginLink()}>
              Log in
            </a>
          </Text>
        </div>
      </div>
    </div>
  );
}

export default PageSideBanner;

import { Card } from '@anchorage/common/dist/components';
import { useFormContext } from '@anchorage/common/dist/components/Form/_reactHookForm';
import {
  FormInput,
  FormSelect,
} from '@anchorage/common/dist/components/Form/components';
import type { SelectOption } from '@anchorage/common/dist/components/SelectV2/types';

import css from './styles.module.scss';

type Props = {
  countryOptions: SelectOption<string, string>[];
  isCountryOptionsLoading: boolean;
};

function OrganizationDetailsContent({
  countryOptions,
  isCountryOptionsLoading,
}: Props) {
  const { control } = useFormContext();

  return (
    <div>
      <Card className={css.container}>
        <FormInput
          control={control}
          id="organizationName"
          name="organizationName"
          label="Organization name"
          required
        />
        <FormSelect
          control={control}
          id="organizationCountry"
          name="organizationCountry"
          label="Country of incorporation"
          options={countryOptions}
          required
          isLoading={isCountryOptionsLoading}
        />
      </Card>
    </div>
  );
}

export { OrganizationDetailsContent };

import { Text } from '@anchorage/common/dist/components';

import css from './styles.module.scss';

import RegistrationCompleteMobile from '../../../../images/registrationCompleteMobile';

const RegistrationCompleteContent = () => {
  return (
    <div className={css.contentWrapper}>
      <div className={css.content}>
        <RegistrationCompleteMobile className={css.illustration} />
        <Text type="metric" size="large" className={css.pageTitle}>
          Registration complete
        </Text>
        <Text>
          Thank you for registering your organization with Porto. We have
          received your details and will email you soon to arrange an onboarding
          session.
        </Text>
        <Text size="small">
          Need help? Feel free to reach out to us at{' '}
          <a href="mailto:help@porto.com" className={css.link}>
            help@porto.com
          </a>
          .
        </Text>
      </div>
    </div>
  );
};

export { RegistrationCompleteContent };

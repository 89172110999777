import cn from 'classnames';

import { Button } from '@anchorage/common/dist/components';

import { PORTO_SELF_REGISTRATION_PAGES } from 'components/Signup/helpers';

import css from './styles.module.scss';

type Props = {
  page: PORTO_SELF_REGISTRATION_PAGES;
  setPage: (page: PORTO_SELF_REGISTRATION_PAGES) => void;
  onSubmit: () => void;
  isDisabled: boolean;
  isLoading: boolean;
};

function PageButtons({
  page,
  setPage,
  onSubmit,
  isDisabled,
  isLoading,
}: Props) {
  if (page === PORTO_SELF_REGISTRATION_PAGES.REGISTRATION_COMPLETE) {
    return null;
  }

  const isFirstPage =
    page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;

  const showBackButton =
    page !== PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE;

  const handleBackButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS);
    }
  };

  const handleContinueButton = () => {
    if (page === PORTO_SELF_REGISTRATION_PAGES.COMPLETE_YOUR_PROFILE) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ORGANIZATION_DETAILS) {
      setPage(PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM);
    } else if (page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM) {
      onSubmit();
    }
  };

  const isLastFormPage = page === PORTO_SELF_REGISTRATION_PAGES.ADD_YOUR_TEAM;

  return (
    <div
      className={cn({
        [css.container!]: true,
        [css.singleButton!]: !showBackButton,
        [css.doubleButton!]: showBackButton,
        [css.firstPageSingleButton!]: isFirstPage,
      })}
    >
      {showBackButton && (
        <Button type="secondary" onClick={handleBackButton}>
          Back
        </Button>
      )}
      <Button
        onClick={handleContinueButton}
        disabled={isDisabled}
        isLoading={isLastFormPage ? isLoading : false}
      >
        {isLastFormPage ? 'Submit' : 'Continue'}
      </Button>
    </div>
  );
}

export default PageButtons;
